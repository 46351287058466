import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/privacy-policy",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/privacyPolicy/${locale}.js`)).default
			return import('@/views/privacyPolicy/PrivacyPolicyPage.vue')
		}
	},
	{
		path: "/confidentialit%C3%A9-politique",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/privacyPolicy/${locale}.js`)).default
			return import('@/views/privacyPolicy/PrivacyPolicyPage.vue')
		}
	},
	{
		path: "/privacidad-pol%C3%ADtica",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/privacyPolicy/${locale}.js`)).default
			return import('@/views/privacyPolicy/PrivacyPolicyPage.vue')
		}
	},
	{
		path: "/privacidade-pol%C3%ADtica",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/privacyPolicy/${locale}.js`)).default
			return import('@/views/privacyPolicy/PrivacyPolicyPage.vue')
		}
	},
	{
		path: "/%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/privacyPolicy/${locale}.js`)).default
			return import('@/views/privacyPolicy/PrivacyPolicyPage.vue')
		}
	},
]
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/500",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/error/${locale}.js`)).default
			return import('@/views/error/Error500Page.vue')
		}
	},
	{
		path: "/404",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/error/${locale}.js`)).default
			return import('@/views/error/Error404Page.vue')
		}
	},
]
export class PageConst {
	/**
	 *根首页
	 */
	static Home = "Home";
	/**
	 *被邀请页
	 */
	static Referer = "Referer";
	/**
	 *邀请页
	 */
	static Invite = "Invite";
	/**
	 *文生图-首页
	 */
	static TxtToImg = "TxtToImg";
	/**
	 *Enhance-图片功能首页
	 */
	static EnhanceImage = "EnhanceImage";
	/**
	 *Enhance-视频功能首页
	 */
	static EnhanceVideo = "EnhanceVideo";
	/**
	 *站长-介绍页
	 */
	static AffiliateIntroduce = "AffiliateIntroduce";
	/**
	 *站长-面板页
	 */
	static AffiliateActivity = "AffiliateActivity";
	/**
	 *历史页-文生图
	 */
	static HistoryTxtToImg = "HistoryTxtToImg";
	/**
	 *历史页-Enhance
	 */
	static HistoryEnhance = "HistoryEnhance";
	/**
	 *个人中心页
	 */
	static UserCenter = "UserCenter";
	/**
	 *邮箱通知配置页
	 */
	static EmailNoticeSetting = "EmailNoticeSetting";
	/**
	 *积分/支付历史页
	 */
	static PaymentOrCreditHistory = "PaymentOrCreditHistory";
	/**
	 *付费页
	 */
	static Pricing = "Pricing";
	/**
	 *联系支持页
	 */
	static ContactSupport = "ContactSupport";
	/**
	 *隐私协议页
	 */
	static PrivacyPolicy = "PrivacyPolicy";
	/**
	 *用户协议页
	 */
	static UserPolicy = "UserPolicy";
	/**
	 *退款协议页
	 */
	static RefundPolicy = "RefundPolicy";
	/**
	 *知识产权页
	 */
	static IntellectualPropertyRights = "IntellectualPropertyRights";
	/**
	 *关于我们页
	 */
	static AboutUs = "AboutUs";
	/**
	 *产品FAQ页
	 */
	static FAQ = "FAQ";
	/**
	 *500页
	 */
	static Error500 = "Error500";
	/**
	 *404页
	 */
	static Error404 = "Error404";
	/**
	 *支付中间响应页
	 */
	static PricingGangplank = "PricingGangplank";
	/**
	 *oceanpayment中间响应页
	 */
	static OceanpaymentWrapper = "OceanpaymentWrapper";
	/**
	 *联邦政策协议页
	 */
	static FLARKLawPolicy = "FLARKLawPolicy";
	/**
	 *图库首页
	 */
	static GalleryHome = "GalleryHome";
	/**
	 *图库详情页
	 */
	static GalleryDetail = "GalleryDetail";
	/**
	 *图库搜索页
	 */
	static GallerySearch = "GallerySearch";
	/**
	 *图库热搜词页
	 */
	static GalleryHot = "GalleryHot";
	/**
	 *图库创作者页
	 */
	static GalleryPublisher = "GalleryPublisher";
	/**
	 *sitemap页
	 */
	static Sitemap = "Sitemap";
}


export const routeConstMapping = {
	'en': {
		"Home": "/",
		"Referer": "/ai-image-generator-refer/",
		"Invite": "/ai-generator-invite/",
		"TxtToImg": "/free-ai-image-generator/",
		"EnhanceImage": "/photo-enhance/",
		"EnhanceVideo": "/video-enhance/",
		"AffiliateIntroduce": "/affiliate-program/",
		"AffiliateActivity": "/affiliate-program-activity/",
		"HistoryTxtToImg": "/history-ai-creations/",
		"HistoryEnhance": "/history-enhance-creations/",
		"UserCenter": "/account-information/",
		"EmailNoticeSetting": "/account-information/notification/",
		"PaymentOrCreditHistory": "/payment-credit-history/",
		"Pricing": "/pricing/",
		"ContactSupport": "/contact-support/",
		"PrivacyPolicy": "/privacy-policy/",
		"UserPolicy": "/user-policy/",
		"RefundPolicy": "/refund-policy/",
		"IntellectualPropertyRights": "/intellectual-property-rights/",
		"AboutUs": "/about-us/",
		"FAQ": "/faq/",
		"Error500": "/500/",
		"Error404": "/404/",
		"PricingGangplank": "/pricing-callback/",
		"OceanpaymentWrapper": "/oceanpayment-handler/",
		"FLARKLawPolicy": "/usc-2257-exemption/",
		"GalleryHome": "/images-photos/",
		"GalleryDetail": "/images-photos/",
		"GallerySearch": "/images-photos/pictures/",
		"GalleryHot": "/images-photos/hot/",
		"GalleryPublisher": "/images-photos/publisher/",
		"Sitemap": "/sitemap/",
	},
	'fr': {
		"Home": "/",
		"Referer": "/ai-image-g%C3%A9n%C3%A9rateur-r%C3%A9f%C3%A9rer/",
		"Invite": "/ai-g%C3%A9n%C3%A9rateur-inviter/",
		"TxtToImg": "/gratuit-ai-image-g%C3%A9n%C3%A9rateur/",
		"EnhanceImage": "/photo-am%C3%A9liorer/",
		"EnhanceVideo": "/vid%C3%A9o-am%C3%A9liorer/",
		"AffiliateIntroduce": "/filiale-programme/",
		"AffiliateActivity": "/filiale-programme-activit%C3%A9/",
		"HistoryTxtToImg": "/histoire-ai-cr%C3%A9ations/",
		"HistoryEnhance": "/histoire-am%C3%A9liorer-cr%C3%A9ations/",
		"UserCenter": "/compte-information/",
		"EmailNoticeSetting": "/informations-sur-le-compte/notification/",
		"PaymentOrCreditHistory": "/paiement-cr%C3%A9dit-histoire/",
		"Pricing": "/tarifs/",
		"ContactSupport": "/contact-soutien/",
		"PrivacyPolicy": "/confidentialit%C3%A9-politique/",
		"UserPolicy": "/utilisateur-politique/",
		"RefundPolicy": "/remboursement-politique/",
		"IntellectualPropertyRights": "/intellectuel-propri%C3%A9t%C3%A9-droits/",
		"AboutUs": "/%C3%A0%20propos-nous/",
		"FAQ": "/faq/",
		"Error500": "/500/",
		"Error404": "/404/",
		"PricingGangplank": "/pricing-callback/",
		"OceanpaymentWrapper": "/oceanpayment-handler/",
		"FLARKLawPolicy": "/exemption-usc-2257/",
		"GalleryHome": "/images-photos/",
		"GalleryDetail": "/images-photos/",
		"GallerySearch": "/images-photos/photos/",
		"GalleryHot": "/images-photos/chaud/",
		"GalleryPublisher": "/images-photos/%C3%A9diteur/",
		"Sitemap": "/sitemap/",
	},
	'es': {
		"Home": "/",
		"Referer": "/ai-imagen-generador-referirse/",
		"Invite": "/ai-generador-invitar/",
		"TxtToImg": "/gratis-ai-imagen-generador/",
		"EnhanceImage": "/foto-mejorar/",
		"EnhanceVideo": "/video-mejorar/",
		"AffiliateIntroduce": "/filial-programa/",
		"AffiliateActivity": "/filial-programa-actividad/",
		"HistoryTxtToImg": "/historia-ai-creaciones/",
		"HistoryEnhance": "/historia-mejorar-creaciones/",
		"UserCenter": "/cuenta-informaci%C3%B3n/",
		"EmailNoticeSetting": "/informaci%C3%B3n-de-cuenta/notificaci%C3%B3n/",
		"PaymentOrCreditHistory": "/pago-cr%C3%A9dito-historia/",
		"Pricing": "/precios/",
		"ContactSupport": "/contacto-apoyo/",
		"PrivacyPolicy": "/privacidad-pol%C3%ADtica/",
		"UserPolicy": "/usuario-pol%C3%ADtica/",
		"RefundPolicy": "/reembolso-pol%C3%ADtica/",
		"IntellectualPropertyRights": "/intelectual-propiedad-derechos/",
		"AboutUs": "/acerca%20de-a%20nosotros/",
		"FAQ": "/preguntas%20frecuentes/",
		"Error500": "/500/",
		"Error404": "/404/",
		"PricingGangplank": "/pricing-callback/",
		"OceanpaymentWrapper": "/oceanpayment-handler/",
		"FLARKLawPolicy": "/usc-2257-exencion/",
		"GalleryHome": "/im%C3%A1genes-fotos/",
		"GalleryDetail": "/im%C3%A1genes-fotos/",
		"GallerySearch": "/im%C3%A1genes-fotos/fotos/",
		"GalleryHot": "/im%C3%A1genes-fotos/caliente/",
		"GalleryPublisher": "/im%C3%A1genes-fotos/editor/",
		"Sitemap": "/sitemap/",
	},
	'pt': {
		"Home": "/",
		"Referer": "/ai-imagem-gerador-referir-se/",
		"Invite": "/ai-gerador-convidar/",
		"TxtToImg": "/livre-ai-imagem-gerador/",
		"EnhanceImage": "/foto-melhorar/",
		"EnhanceVideo": "/v%C3%ADdeo-melhorar/",
		"AffiliateIntroduce": "/afiliado-programa/",
		"AffiliateActivity": "/afiliado-programa-atividade/",
		"HistoryTxtToImg": "/hist%C3%B3ria-ai-cria%C3%A7%C3%B5es/",
		"HistoryEnhance": "/hist%C3%B3ria-melhorar-cria%C3%A7%C3%B5es/",
		"UserCenter": "/conta-Informa%C3%A7%C3%A3o/",
		"EmailNoticeSetting": "/informa%C3%A7%C3%B5es-da-conta/notifica%C3%A7%C3%A3o/",
		"PaymentOrCreditHistory": "/pagamento-cr%C3%A9dito-hist%C3%B3ria/",
		"Pricing": "/pre%C3%A7os/",
		"ContactSupport": "/contato-apoiar/",
		"PrivacyPolicy": "/privacidade-pol%C3%ADtica/",
		"UserPolicy": "/usu%C3%A1rio-pol%C3%ADtica/",
		"RefundPolicy": "/reembolso-pol%C3%ADtica/",
		"IntellectualPropertyRights": "/intelectual-propriedade-direitos/",
		"AboutUs": "/sobre-n%C3%B3s/",
		"FAQ": "/Perguntas%20frequentes/",
		"Error500": "/500/",
		"Error404": "/404/",
		"PricingGangplank": "/pricing-callback/",
		"OceanpaymentWrapper": "/oceanpayment-handler/",
		"FLARKLawPolicy": "/isen%C3%A7%C3%A3o-usc-2257/",
		"GalleryHome": "/imagens-fotos/",
		"GalleryDetail": "/imagens-fotos/",
		"GallerySearch": "/imagens-fotos/fotos/",
		"GalleryHot": "/imagens-fotos/quente/",
		"GalleryPublisher": "/imagens-fotos/editor/",
		"Sitemap": "/sitemap/",
	},
	'ja': {
		"Home": "/",
		"Referer": "/%E3%82%A2%E3%82%A4-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E5%8F%82%E7%85%A7%E3%81%99%E3%82%8B/",
		"Invite": "/%E3%82%A2%E3%82%A4-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E6%8B%9B%E5%BE%85%E3%81%99%E3%82%8B/",
		"TxtToImg": "/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF/",
		"EnhanceImage": "/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
		"EnhanceVideo": "/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
		"AffiliateIntroduce": "/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0/",
		"AffiliateActivity": "/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0-%E6%B4%BB%E5%8B%95/",
		"HistoryTxtToImg": "/%E6%AD%B4%E5%8F%B2-%E3%81%82%E3%81%84-%E4%BD%9C%E5%93%81/",
		"HistoryEnhance": "/%E6%AD%B4%E5%8F%B2-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E4%BD%9C%E5%93%81/",
		"UserCenter": "/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88-%E6%83%85%E5%A0%B1/",
		"EmailNoticeSetting": "/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E6%83%85%E5%A0%B1/%E9%80%9A%E7%9F%A5/",
		"PaymentOrCreditHistory": "/%E6%94%AF%E6%89%95%E3%81%84-%E3%82%AF%E3%83%AC%E3%82%B8%E3%83%83%E3%83%88-%E6%AD%B4%E5%8F%B2/",
		"Pricing": "/%E4%BE%A1%E6%A0%BC/",
		"ContactSupport": "/%E6%8E%A5%E8%A7%A6-%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88/",
		"PrivacyPolicy": "/%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
		"UserPolicy": "/%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
		"RefundPolicy": "/%E8%BF%94%E9%87%91-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
		"IntellectualPropertyRights": "/%E7%9F%A5%E7%9A%84-%E8%B2%A1%E7%94%A3-%E6%A8%A9%E5%88%A9/",
		"AboutUs": "/%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-%E7%A7%81%E3%81%9F%E3%81%A1/",
		"FAQ": "/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/",
		"Error500": "/500/",
		"Error404": "/404/",
		"PricingGangplank": "/pricing-callback/",
		"OceanpaymentWrapper": "/oceanpayment-handler/",
		"FLARKLawPolicy": "/usc-2257-%E5%85%8D%E9%99%A4/",
		"GalleryHome": "/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/",
		"GalleryDetail": "/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/",
		"GallerySearch": "/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E5%86%99%E7%9C%9F/",
		"GalleryHot": "/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E7%86%B1%E3%81%84/",
		"GalleryPublisher": "/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E7%99%BA%E8%A1%8C%E8%80%85/",
		"Sitemap": "/sitemap/",
	},
};


import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/history-ai-creations",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryTxtToImgPage.vue')
		}
	},
	{
		path: "/histoire-ai-cr%C3%A9ations",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryTxtToImgPage.vue')
		}
	},
	{
		path: "/historia-ai-creaciones",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryTxtToImgPage.vue')
		}
	},
	{
		path: "/hist%C3%B3ria-ai-cria%C3%A7%C3%B5es",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryTxtToImgPage.vue')
		}
	},
	{
		path: "/%E6%AD%B4%E5%8F%B2-%E3%81%82%E3%81%84-%E4%BD%9C%E5%93%81",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryTxtToImgPage.vue')
		}
	},
	{
		path: "/history-enhance-creations",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryEnhancePage.vue')
		}
	},
	{
		path: "/histoire-am%C3%A9liorer-cr%C3%A9ations",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryEnhancePage.vue')
		}
	},
	{
		path: "/historia-mejorar-creaciones",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryEnhancePage.vue')
		}
	},
	{
		path: "/hist%C3%B3ria-melhorar-cria%C3%A7%C3%B5es",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryEnhancePage.vue')
		}
	},
	{
		path: "/%E6%AD%B4%E5%8F%B2-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E4%BD%9C%E5%93%81",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/history/${locale}.js`)).default
			return import('@/views/history/HistoryEnhancePage.vue')
		}
	},
]
import { createRouter, createWebHistory } from 'vue-router';
import home from './module/home';
import refer from './module/refer';
import invite from './module/invite';
import txtToImg from './module/txtToImg';
import enhance from './module/enhance';
import affiliate from './module/affiliate';
import history from './module/history';
import userCenter from './module/userCenter';
import pricing from './module/pricing';
import contactSupport from './module/contactSupport';
import privacyPolicy from './module/privacyPolicy';
import userPolicy from './module/userPolicy';
import refundPolicy from './module/refundPolicy';
import intellectualPropertyRights from './module/intellectualPropertyRights';
import aboutUs from './module/aboutUs';
import faq from './module/faq';
import error from './module/error';
import FLARKLaw from './module/FLARKLaw';


const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			children: [
				...home, 
				...refer, 
				...invite, 
				...txtToImg, 
				...enhance, 
				...affiliate, 

      {
        path: '/',
        children: [...history],
      },
      				...userCenter, 
				...pricing, 
				...contactSupport, 
				...privacyPolicy, 
				...userPolicy, 
				...refundPolicy, 
				...intellectualPropertyRights, 
				...aboutUs, 
				...faq, 
				...error, 
				...FLARKLaw, 

        {
          // 捕获英文不存在页面
          path: '/:catchAll(.*)',
          redirect: '/404',
        },
  			],
		},
	],
});


export default router;
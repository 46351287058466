import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/ai-image-generator-refer/:key",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refer/${locale}.js`)).default
			return import('@/views/refer/RefererPage.vue')
		}
	},
	{
		path: "/ai-image-g%C3%A9n%C3%A9rateur-r%C3%A9f%C3%A9rer/:key",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refer/${locale}.js`)).default
			return import('@/views/refer/RefererPage.vue')
		}
	},
	{
		path: "/ai-imagen-generador-referirse/:key",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refer/${locale}.js`)).default
			return import('@/views/refer/RefererPage.vue')
		}
	},
	{
		path: "/ai-imagem-gerador-referir-se/:key",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refer/${locale}.js`)).default
			return import('@/views/refer/RefererPage.vue')
		}
	},
	{
		path: "/%E3%82%A2%E3%82%A4-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E5%8F%82%E7%85%A7%E3%81%99%E3%82%8B/:key",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refer/${locale}.js`)).default
			return import('@/views/refer/RefererPage.vue')
		}
	},
]
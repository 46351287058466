<template>
    <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { AnalyticHub } from './script/analytic';
import { User } from './stores/user';

let initThirdPart;
initThirdPart = () => {
    AnalyticHub.getInstance.initAnalytic();
    User.getInstance.initGoogleSDK();
    window.removeEventListener('touchstart', initThirdPart);
    window.removeEventListener('click', initThirdPart);
    document.removeEventListener('scroll', initThirdPart);
};

onMounted(() => {
    const isPrerenderer = (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.foo == 'bar');
    if (isPrerenderer) {
        setTimeout(() => {
            document.dispatchEvent(new Event('render-event'));
        }, 5000);
    }

    AnalyticHub.getInstance.logApp();
    window.addEventListener('touchstart', initThirdPart);
    window.addEventListener('click', initThirdPart);
    document.addEventListener('scroll', initThirdPart);
});
</script>

<style scoped lang="scss"></style>

import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/photo-enhance",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceImagePage.vue')
		}
	},
	{
		path: "/photo-am%C3%A9liorer",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceImagePage.vue')
		}
	},
	{
		path: "/foto-mejorar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceImagePage.vue')
		}
	},
	{
		path: "/foto-melhorar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceImagePage.vue')
		}
	},
	{
		path: "/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceImagePage.vue')
		}
	},
	{
		path: "/video-enhance",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceVideoPage.vue')
		}
	},
	{
		path: "/vid%C3%A9o-am%C3%A9liorer",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceVideoPage.vue')
		}
	},
	{
		path: "/video-mejorar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceVideoPage.vue')
		}
	},
	{
		path: "/v%C3%ADdeo-melhorar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceVideoPage.vue')
		}
	},
	{
		path: "/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/enhance/${locale}.js`)).default
			return import('@/views/enhance/EnhanceVideoPage.vue')
		}
	},
]
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/faq",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/faq/${locale}.js`)).default
			return import('@/views/faq/FAQPage.vue')
		}
	},
	{
		path: "/preguntas%20frecuentes",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/faq/${locale}.js`)).default
			return import('@/views/faq/FAQPage.vue')
		}
	},
	{
		path: "/Perguntas%20frequentes",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/faq/${locale}.js`)).default
			return import('@/views/faq/FAQPage.vue')
		}
	},
	{
		path: "/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/faq/${locale}.js`)).default
			return import('@/views/faq/FAQPage.vue')
		}
	},
]
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/about-us",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/aboutUs/${locale}.js`)).default
			return import('@/views/aboutUs/AboutUs.vue')
		}
	},
	{
		path: "/%C3%A0%20propos-nous",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/aboutUs/${locale}.js`)).default
			return import('@/views/aboutUs/AboutUs.vue')
		}
	},
	{
		path: "/acerca%20de-a%20nosotros",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/aboutUs/${locale}.js`)).default
			return import('@/views/aboutUs/AboutUs.vue')
		}
	},
	{
		path: "/sobre-n%C3%B3s",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/aboutUs/${locale}.js`)).default
			return import('@/views/aboutUs/AboutUs.vue')
		}
	},
	{
		path: "/%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-%E7%A7%81%E3%81%9F%E3%81%A1",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/aboutUs/${locale}.js`)).default
			return import('@/views/aboutUs/AboutUs.vue')
		}
	},
]
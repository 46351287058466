import PageLoading from './PageLoading.vue';
import { createApp } from 'vue';

const PageLoadingPlugin = {
  install (Vue) {
    const LoadingConstructor = createApp(PageLoading);
    const instance = LoadingConstructor.mount(document.createElement('div'));
    
    document.body.appendChild(instance.$el);

    Vue.config.globalProperties.$pageLoading = {
      show: () => instance.show(),
      hide: () => instance.hide(),
    };
  },
};

export default PageLoadingPlugin;

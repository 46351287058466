import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/user-policy",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userPolicy/${locale}.js`)).default
			return import('@/views/userPolicy/UserPolicyPage.vue')
		}
	},
	{
		path: "/utilisateur-politique",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userPolicy/${locale}.js`)).default
			return import('@/views/userPolicy/UserPolicyPage.vue')
		}
	},
	{
		path: "/usuario-pol%C3%ADtica",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userPolicy/${locale}.js`)).default
			return import('@/views/userPolicy/UserPolicyPage.vue')
		}
	},
	{
		path: "/usu%C3%A1rio-pol%C3%ADtica",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userPolicy/${locale}.js`)).default
			return import('@/views/userPolicy/UserPolicyPage.vue')
		}
	},
	{
		path: "/%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userPolicy/${locale}.js`)).default
			return import('@/views/userPolicy/UserPolicyPage.vue')
		}
	},
]
import { CommonUtility } from './utility';
import { User } from '@/stores/user';
import { Config } from '@/stores/config';
import { ENV } from './proj_env';
import { API } from './api';
import { AnalyticHub } from './analytic';
import InfoDialogPlugin from '@/components/common/dialog/infoDialog';
import ToastPlugin from '@/plugin/toast';
import ModalPlugin from '@/components/common/modal/modalPlugin';
import VueLazyLoad from 'vue3-lazyload';
import { createPinia } from 'pinia';
import FeedbackDialogPlugin from '@/components/common/dialog/feedbackDialog';
import TxtToImgFeedbackDialogPlugin from '@/components/common/dialog/txteToImgfeedbackDialog';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import PageLoadingPlugin from '@/plugin/pageLoading';

export default {
  install: (app) => {
    app.use(createPinia());
    app.use(InfoDialogPlugin);
    app.use(ToastPlugin);
    app.use(VueLazyLoad);
    app.use(FeedbackDialogPlugin);
    app.use(TxtToImgFeedbackDialogPlugin);
    app.use(ElementPlus);
    app.use(ModalPlugin);

    app.use(PageLoadingPlugin);
    app.config.globalProperties.$utility = CommonUtility;
    app.config.globalProperties.$user = User.getInstance;
    app.config.globalProperties.$config = Config.getInstance;
    app.config.globalProperties.$env = ENV;
    app.config.globalProperties.$api = API;
    app.config.globalProperties.$analytic = AnalyticHub.getInstance;
    CommonUtility.init();

    window.addEventListener('message', (event) => {
      if (event.data == 'loadUserInfo') {
        User.getInstance.loadUserInfo();
      }
    });
  },
};

import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/usc-2257-exemption",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/FLARKLaw/${locale}.js`)).default
			return import('@/views/FLARKLaw/FLARKLawPage.vue')
		}
	},
	{
		path: "/exemption-usc-2257",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/FLARKLaw/${locale}.js`)).default
			return import('@/views/FLARKLaw/FLARKLawPage.vue')
		}
	},
	{
		path: "/usc-2257-exencion",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/FLARKLaw/${locale}.js`)).default
			return import('@/views/FLARKLaw/FLARKLawPage.vue')
		}
	},
	{
		path: "/isen%C3%A7%C3%A3o-usc-2257",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/FLARKLaw/${locale}.js`)).default
			return import('@/views/FLARKLaw/FLARKLawPage.vue')
		}
	},
	{
		path: "/usc-2257-%E5%85%8D%E9%99%A4",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/FLARKLaw/${locale}.js`)).default
			return import('@/views/FLARKLaw/FLARKLawPage.vue')
		}
	},
]
import { createI18n } from 'vue-i18n';
import { ref } from 'vue';
import { routerPageURL, goRouterPage, switchLocale } from '@/router/tool';
import { ENV } from '@/script/proj_env';

export const i18n = createI18n({
    locale: ENV.currentConfig.lang,
    messages: {},
});
i18n.global.newTab = ref(false);

export default {
    install: (app) => {
        app.use(i18n);
        app.config.globalProperties.i18n = i18n;
        app.config.globalProperties.$jumpOtherPage = jumpOtherPage;
        app.config.globalProperties.$otherPageUrl = otherPageUrl;
        app.config.globalProperties.$switchI18n = switchI18n;
    },
};

/**
 * 跳转页面
 * @param {页面key} page
 * @param {地址栏参数} params
 */
export function jumpOtherPage(page, params) {
    goRouterPage(page, i18n.global.locale, params, i18n.global.newTab.value);
}
/**
 * 获取页面URL
 * @param {页面key} page
 * @param {地址栏参数} params
 * @returns 目标路径
 */
export function otherPageUrl(page, params) {
    return routerPageURL(page, i18n.global.locale, params);
}

/**
 * 切换页面语言
 * @param {i18n code 码} locale 
 */
export function switchI18n(locale) {
    return switchLocale(locale);
}
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/ai-generator-invite",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/invite/${locale}.js`)).default
			return import('@/views/invite/InvitePage')
		}
	},
	{
		path: "/ai-g%C3%A9n%C3%A9rateur-inviter",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/invite/${locale}.js`)).default
			return import('@/views/invite/InvitePage')
		}
	},
	{
		path: "/ai-generador-invitar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/invite/${locale}.js`)).default
			return import('@/views/invite/InvitePage')
		}
	},
	{
		path: "/ai-gerador-convidar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/invite/${locale}.js`)).default
			return import('@/views/invite/InvitePage')
		}
	},
	{
		path: "/%E3%82%A2%E3%82%A4-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E6%8B%9B%E5%BE%85%E3%81%99%E3%82%8B",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/invite/${locale}.js`)).default
			return import('@/views/invite/InvitePage')
		}
	},
]
<template>
    <div v-if="visible" class="dialog-overlay">
        <div class="dialog">
            <img v-if="type !== InfoDialogType.Normal" class="cancle-btn" :src="assetImage('close_icon')"
                @click="cancel" />
            <img :class="topImageStyle" :src="assetImage(`${imageSrc}`)" />
            <div :style="space(26, 20)"></div>
            <div class="title">{{ title }}</div>
            <div :style="space(21, 20)"></div>

            <!-- 图片或视频太大 -->
            <div v-if="type.includes('big')" class="row">
                <div class="text text2">{{ text[0] }}</div>
                <div class="text text3">&nbsp;{{ text[1] }}&nbsp;</div>
                <div class="text text2">{{ text[2] }}</div>
            </div>
            <div v-else-if="type === InfoDialogType.VideoTooLong" class="row">
                <div class="text text2">{{ text[0] }}</div>
                <div class="text text3">&nbsp;{{ text[1] }}</div>
            </div>
            <div v-else class="text">{{ text }}</div>
            <div :style="space(30, 20)"></div>

            <div v-if="type === InfoDialogType.Normal" class="row">
                <FyButton @click="cancel" class="normal-btn">
                    {{ leftBtnText }}
                </FyButton>
                <div style="width: 12px;"></div>
                <FyButton status="highlight" supportHover="true" @click="confirm" class="normal-btn">
                    {{ rightBtnText }}
                </FyButton>
            </div>
            <FyButton v-else status="highlight" supportHover="true" @click="confirm" class="confirm">
                <div class="btn-text">{{ btnText }}</div>
                <img v-if="!type.includes('too')" class="right-icon" :src="assetImage('right')" />
            </FyButton>

        </div>
    </div>
</template>


<script>
import FyButton from '../button/FyButton.vue';
import { CommonUtility } from '@/script/utility';
import { InfoDialogType } from './infoDialog';


export default {
    name: 'InfoDialog',
    components: { FyButton },
    data () {
        return {
            defaultContent: ['advanced_features', 'Warning', 'Dialog type error', 'Confirm'],
            faceSwapAdvancedFeatures: ['advanced_features', this.$t('infoDialog.advancedFeatures'), this.$t('infoDialog.advancedFeaturesText'), this.$t('infoDialog.choosePlan')],
            insufficientCredits: ['insufficient_credits', this.$t('infoDialog.insufficientCredits'), this.$t('infoDialog.insufficientCreditsText'), this.$t('infoDialog.getMoreCredits')],
            videoTooBig: ['video_too_big', this.$t('infoDialog.videoTooBig'), [this.$t('infoDialog.videoCannotExceed'), '500MB',  this.$t('infoDialog.inFileSize')], this.$t('infoDialog.selectAnotherFile')],
            photoTooBig: ['photo_too_big', this.$t('infoDialog.photoTooBig'), [this.$t('infoDialog.photoCannotExceed'), '10MB', this.$t('infoDialog.inFileSize')], this.$t('infoDialog.selectAnotherFile')],
            videoTooLong: ['video_too_big',  this.$t('infoDialog.videoTooLong'), [this.$t('infoDialog.photoCannotExceed'), this.$t('infoDialog.tenMin')], this.$t('infoDialog.selectAnotherFile')],
            lookslikeUploadFaceOops: ['looklike_upload_face_oops', this.$t('lookslikeUploadOpps.title'), this.$t('lookslikeUploadOpps.subtitle'), this.$t('lookslikeUploadOpps.unlockLimit')],
            InfoDialogType,
            visible: false,
            type: '',
            value: null,
            imageSrc: '',
            title: '',
            text: '',
            confirmBtnText: '',
            leftBtnText: '',
            rightBtnText: '',
        };
    },
    methods: {
        showDialog (type, value, imageSrc, title, text, leftBtnText, rightBtnText) {
            let msg;
            switch (type) {
                case InfoDialogType.Normal:
                    msg = [imageSrc, title, text];
                    this.leftBtnText = leftBtnText;
                    this.rightBtnText = rightBtnText;
                    break;
                case InfoDialogType.looklikeUploadFaceOops:
                    msg = this.lookslikeUploadFaceOops;
                    break;
                case InfoDialogType.AdvancedFeatures:
                    msg = this.faceSwapAdvancedFeatures;
                    break;
                case InfoDialogType.InsufficientCredits:
                    msg = this.insufficientCredits;
                    break;
                case InfoDialogType.PhotoTooBig:
                    msg = this.photoTooBig;
                    break;
                case InfoDialogType.VideoTooBig:
                    msg = this.videoTooBig;
                    break;
                case InfoDialogType.VideoTooLong:
                    msg = this.videoTooLong;
                    break;
                default:
                    msg = this.defaultContent;
            }
            this.imageSrc = msg[0];
            this.title = msg[1];
            if (value) {
                this.text = `${msg[2]}${value}`;
            } else {
                this.text = msg[2];
            }
            this.btnText = msg[3];
            this.type = type;
            this.value = value;
            this.visible = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        space (large, narrow) {
            if (window.innerWidth > 430) {
                return `height:${large}px`;
            } 
                return `height:${narrow}px`;
        },
        assetImage (name) {
            return CommonUtility.assetImage(name);
        },
        confirm () {
            this.resolve(true);
            this.closeDialog();
        },
        cancel () {
            this.resolve(false);
            this.closeDialog();
        },
        closeDialog () {
            this.visible = false;
        },
    },

    computed: {
        topImageStyle () {
            switch (this.type) {
                case InfoDialogType.Normal:
                    return 'top-image4';
                case InfoDialogType.AdvancedFeatures:
                    return 'top-image1';
                case InfoDialogType.InsufficientCredits:
                    return 'top-image2';
                case InfoDialogType.PhotoTooBig:
                case InfoDialogType.VideoTooBig:
                case InfoDialogType.VideoTooLong:
                    return 'top-image3';
                case InfoDialogType.looklikeUploadFaceOops:
                    return 'top-image5';
                default:
                    return 'top-image4';
            }
        },
    },

};
</script>

<style scoped lang="scss"> 
@import './common.css';

.dialog {
    width: 390px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.top-image1 {
    width: 183px;
    height: 119px;
}

.top-image2 {
    width: 161px;
    height: 131px;
}

.top-image3 {
    width: 164px;
    height: 119px;
}

.top-image4 {
    width: 100px;
    height: 100px;
}
.top-image5 {
    width: 161px;
    height: 131px;
}

.title {
    width: 286px;
    // height: 28px;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    line-height: 28px;
    text-align: center;
}

.text {
    width: 304px;
    height: auto;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    text-align: center;
}

.text2 {
    width: auto;
}

.text3 {
    width: auto;
    color: rgba(224, 84, 53, 1);
}

.normal-btn {
    width: 157px;
    height: 45px;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    border-radius: 8px;
    background: #F4F5F5;
}

.confirm {
    width: 330px;
    height: 45px;
}

.btn-text {
    font-weight: 500;
    font-size: 16px;
    color: white;
    line-height: 24px;
    margin-right: 9px;
}

.right-icon {
    width: 19px;
    height: 14px;
}


@media (max-width: 430px) {

    .top-image1 {
        width: 146px;
        height: 95px;
    }

    .top-image2 {
        width: 115px;
        height: 94px;
    }

    .top-image3 {
        width: 131px;
        height: 95px;
    }

    .top-image4 {
        width: 80px;
        height: 80px;
    }

    .top-image5 {
        width: 115px;
        height: 94px;
    }

    .confirm {
        width: 219px;
        height: 36px;
    }

    .dialog {
        width: 90%;
        height: auto;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px 35px 30px 35px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .title {
        width: 264px;
        // height: 22px;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 22px;
        text-align: center;
    }

    .text {
        width: 264px;
        height: auto;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        line-height: 15px;
        text-align: center;
    }

    .text2 {
        width: auto;
    }

    .text3 {
        width: auto;
        color: rgba(224, 84, 53, 1);
    }

    .btn-text {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        line-height: 16px;
        text-align: right;
        margin-right: 5px;
    }

    .right-icon {
        width: 16px;
        height: 12px;
    }

    .normal-btn {
        width: 146px;
        height: 36px;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        line-height: 22px;
        border-radius: 8px;
        background: #F4F5F5;
    }

}
:deep(.highlight){
  background: #FF7839;
  color: #FFFFFF !important;
}
</style>
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/affiliate-program",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateIntroducePage.vue')
		}
	},
	{
		path: "/filiale-programme",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateIntroducePage.vue')
		}
	},
	{
		path: "/filial-programa",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateIntroducePage.vue')
		}
	},
	{
		path: "/afiliado-programa",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateIntroducePage.vue')
		}
	},
	{
		path: "/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateIntroducePage.vue')
		}
	},
	{
		path: "/affiliate-program-activity",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateActivityPage.vue')
		}
	},
	{
		path: "/filiale-programme-activit%C3%A9",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateActivityPage.vue')
		}
	},
	{
		path: "/filial-programa-actividad",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateActivityPage.vue')
		}
	},
	{
		path: "/afiliado-programa-atividade",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateActivityPage.vue')
		}
	},
	{
		path: "/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0-%E6%B4%BB%E5%8B%95",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/affiliate/${locale}.js`)).default
			return import('@/views/affiliate/AffiliateActivityPage.vue')
		}
	},
]
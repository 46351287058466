<template>
    <div class="loading-overlay" v-if="visible">
        <div class="rect">
            <div class=loader></div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const visible = ref(false);
const show = () => {
    visible.value = true;
    document.body.style.overflow = 'hidden';
};
const hide = () => {
    visible.value = false;
    document.body.style.overflow = 'auto';
};

defineExpose({
    show,
    hide,
});
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 5.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

@keyframes l27 {
    100% {
        transform: rotate(1turn)
    }
}

@media (min-width: 1230px) {
    .loading-overlay {
        position: fixed;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.2);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        top: 0;
        left: 0;
        width: calc(100vw);
        height: calc(100vh);
        display: flex;
        justify-content: center;
        align-items: center;

        .rect {
            width: 130px;
            height: 130px;
            border-radius: 14px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;

            .loader {
                --d: 22px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                color: #FF7839;
                box-shadow:
                    calc(1*var(--d)) calc(0*var(--d)) 0 0,
                    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 1px,
                    calc(0*var(--d)) calc(1*var(--d)) 0 2px,
                    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
                    calc(-1*var(--d)) calc(0*var(--d)) 0 4px,
                    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px;
                animation: l27 1s infinite steps(8);
            }
        }
    }

}
@media (max-width: 1229px) {
    .loading-overlay {
        position: fixed;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.2);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        top: 0;
        left: 0;
        width: calc(100vw);
        height: calc(100vh);
        display: flex;
        justify-content: center;
        align-items: center;

        .rect {
            width: 100px;
            height: 100px;
            border-radius: 14px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;

            .loader {
                --d: 18px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                color: #FF7839;
                box-shadow:
                    calc(1*var(--d)) calc(0*var(--d)) 0 0,
                    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 0.9px,
                    calc(0*var(--d)) calc(1*var(--d)) 0 1.7px,
                    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 2.5px,
                    calc(-1*var(--d)) calc(0*var(--d)) 0 3.2px,
                    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 4px;
                animation: l27 1s infinite steps(8);
            }
        }
    }

}
</style>
import { createApp } from 'vue';
import InfoDialog from './InfoDialog.vue';
import { i18n } from '@/i18n/index';


const InfoDialogPlugin = {

    install(app) {
        const DialogConstructor = createApp(InfoDialog);
        DialogConstructor.use(i18n);
        DialogConstructor.config.globalProperties.i18n = i18n;

        let instance;

        function showDialog(type, {
            value = '',
            imageSrc = 'dialog_delete',
            title = '',
            text = '',
            leftBtnText = 'Cancel',
            rightBtnText = 'Confirm',
        } = {}) {
            if (!instance) {
                const container = document.createElement('div');
                document.body.appendChild(container);
                instance = DialogConstructor.mount(container);
            }
            return instance.showDialog(type, value, imageSrc, title, text, leftBtnText, rightBtnText);
        }
        app.config.globalProperties.$showInfoDialog = showDialog;
    },
};

export default InfoDialogPlugin;

export const InfoDialogType = {
    AdvancedFeatures: 'advanced_features',
    InsufficientCredits: 'insufficient_credits',
    VideoTooBig: 'video_too_big',
    PhotoTooBig: 'photo_too_big',
    VideoTooLong: 'video_too_long',
    looklikeUploadFaceOops: 'looklike_upload_face_oops',
    Normal: 'normal', // 标准弹框样式
};

import { reactive, computed, ref  } from 'vue';
import { API } from '@/script/api.js';

export class Config {
    static get getInstance () {
        if (!this.instance) {
            this.instance = new Config();
        }
        return this.instance;
    }

    get affiliateUrl () {
        return computed(() => this.config.affiliateUrl || '');
    }

    get apiUrl () {
        return computed(() => this.config.apiUrl || '');
    }

    get freeBatchSize () {
        return computed(() => {
            let value = this.config.freeBatchSize || '';
            if (value) {
                return value.substring(1, value.length - 1).split(',').map((ele) => parseInt(ele));
            }
                return [4, 2, 1];
        });
    }

    get paidBatchSize () {
        return computed(() => {
            let value = this.config.paidBatchSize || '';
            if (value) {
                return value.substring(1, value.length - 1).split(',').map((ele) => parseInt(ele));
            }
            return [4, 2, 1];
        });
    }

    get paymentMethods () {
        return computed(() => {
            let value = this.config.paymentMethod || '';
            return value.split(',').map((ele) => ele.trim());
        });
    }

    get paymentHasKodepay () {
        return this.paymentMethods.value.indexOf('Kodepay') != -1;
    }
    get paymentHasPaypal () {
        return this.paymentMethods.value.indexOf('Paypal') != -1;
    }

    get paymentHasPaypalCredit () {
        return this.paymentMethods.value.indexOf('PaypalCredit') != -1;
    }

    get paymentHasStripe () {
        return this.paymentMethods.value.indexOf('Stripe') != -1;
    }

    get paypalPaymentActive () {
        return computed(() => this.paymentMethods.indexOf('Paypal'));
    }

    get stripePaymentActive () {
        return computed(() => this.paymentMethods.indexOf('Stripe'));
    }

    constructor () {
        this.status = ref(0);
        let configStr = localStorage.getItem('config');
        if (configStr) {
            this.config = reactive(JSON.parse(configStr));
        }else {
            this.config = reactive({});
        }
    }

    async init () {
        await this.loadConfig();
    }
 
    async loadConfig () {
        this.status.value = 1;
        try {
            let resp = await API.loadConfigInfo();
            if (resp.code != 200) {
                this.status.value = 0;
                return false;
            }
            this.syncConfig(resp.data);
            this.status.value = 2;
            return true;
        }catch (_) {
            this.status.value = 0;
            return false;
        }
    }

    syncConfig (config) {
        this.config = reactive(config);
        let configStr = JSON.stringify(config);
        localStorage.setItem('config', configStr);
    }
}
import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/pricing",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingPage.vue')
		}
	},
	{
		path: "/tarifs",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingPage.vue')
		}
	},
	{
		path: "/precios",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingPage.vue')
		}
	},
	{
		path: "/pre%C3%A7os",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingPage.vue')
		}
	},
	{
		path: "/%E4%BE%A1%E6%A0%BC",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingPage.vue')
		}
	},
	{
		path: "/pricing-callback",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/PricingGangplankPage.vue')
		}
	},
	{
		path: "/oceanpayment-handler",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/pricing/${locale}.js`)).default
			return import('@/views/pricing/OceanpaymentWrapperPage.vue')
		}
	},
]
import { createApp } from 'vue';
import ToastComponent from './AppToast.vue';

/**
 * 
  const Toast = {  
    info: 'info',
    error: 'error',
  };
 * 
 * **/ 


const ToastPlugin = {
  install (app) {
    const ToastConstructor = createApp(ToastComponent);
    let instance;

    function showToast (message, leftIconSrc = 'icon_confirm', type = 'info', duration = 3500) {
      if (!instance) {
        const container = document.createElement('div');
        document.body.appendChild(container);
        instance = ToastConstructor.mount(container);
      }
      instance.show(message, leftIconSrc, type, duration);
    }

    function showErrorToast (message, leftIconSrc = 'icon_warning') {
      showToast(message, leftIconSrc, 'warning');
    }
    app.config.globalProperties.$showToast = showToast;
    app.config.globalProperties.$showErrorToast = showErrorToast;
  },
};
export default ToastPlugin;



import { domain } from '@/script/proj_env';
import { PageConst, routeConstMapping } from './const';
export const RouterPageConst = PageConst;

/**
 * 获取对应route的路径
 * @param {路由所对应的Key} page 
 * @param {i18n code 码} locale 
 * @param {地址栏参数} params
 */
export function routerPageURL(page, locale, params) {
	let url = routeConstMapping[locale][page];
	const pairs = [];
	if (params) {
		for (const key of Object.keys(params)) {
			pairs.push(`${key}=${params[key]}`);
		}
	}
	if (pairs.length > 0) {
		url = `${url}?${pairs.join('&')}`;
	}
	return url;
}

/**
 * 跳转对应的route页面
 * @param {路由所对应的Key} page 
 * @param {i18n code 码} locale 
 * @param {地址栏参数} params
 * @param {是否新开页签} newTab
 */
export function goRouterPage(page, locale, params, newTab = false) {
	const url = routerPageURL(page, locale, params);
	if (newTab) {
		window.open(url);
	} else {
		window.location.href = url;
	}
}

/**
 * 切换页面语言
 * @param {i18n code 码} locale 
 */
export function switchLocale(locale) {
	window.location.href = `${domain[locale]}`;
}
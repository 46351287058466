import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/intellectual-property-rights",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/intellectualPropertyRights/${locale}.js`)).default
			return import('@/views/intellectualPropertyRights/IntellectualPropertyRightsPage.vue')
		}
	},
	{
		path: "/intellectuel-propri%C3%A9t%C3%A9-droits",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/intellectualPropertyRights/${locale}.js`)).default
			return import('@/views/intellectualPropertyRights/IntellectualPropertyRightsPage.vue')
		}
	},
	{
		path: "/intelectual-propiedad-derechos",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/intellectualPropertyRights/${locale}.js`)).default
			return import('@/views/intellectualPropertyRights/IntellectualPropertyRightsPage.vue')
		}
	},
	{
		path: "/intelectual-propriedade-direitos",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/intellectualPropertyRights/${locale}.js`)).default
			return import('@/views/intellectualPropertyRights/IntellectualPropertyRightsPage.vue')
		}
	},
	{
		path: "/%E7%9F%A5%E7%9A%84-%E8%B2%A1%E7%94%A3-%E6%A8%A9%E5%88%A9",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/intellectualPropertyRights/${locale}.js`)).default
			return import('@/views/intellectualPropertyRights/IntellectualPropertyRightsPage.vue')
		}
	},
]
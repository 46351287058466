<template>
    <div v-if="visible" class="dialog-overlay">
        <div class="dialog">

            <div class="title1">{{ $t("feedbackDialog.title") }}</div>
          <div class=" cancle-btn" @click.stop="cancelDialog">
            <img class="def_cla_img" :src="assetImage('bul_cla_def')" alt=""/>
            <img class="hove_cla_img" :src="assetImage('bul_cla_def_hove')" alt=""/>
          </div>
<!--            <img class="cancle-btn" :src="assetImage('close_icon')" @click="cancelDialog" />-->
            <div :style="space(26, 20)"></div>
            <div class="title2">{{ $t("feedbackDialog.subTitle1") }}</div>
            <div :style="space(26, 10)"></div>
            <div class="wrap">
              <div class="item_box" v-for="(item, index) in options" :class=" item.selected ? 'item_box_check' : 'item_box_hove'" @click.stop="itemOptionClick(index)" :key="index">
                {{ item.msg }}
              </div>
            </div>
            <div :style="space(10, 20)"></div>
            <div class="title2">{{ $t("feedbackDialog.subTitle2") }}</div>
            <div style="height: 10px;"></div>
            <div class="textarea-container">
                <textarea class="textarea" v-model="message" :placeholder="$t('feedbackDialog.placeholder')">
                </textarea>
            </div>
            <div :style="space(30, 20)"></div>
            <FyButton :status="confirmBtnEnable ? 'highlight' : 'unable'" :supportHover="confirmBtnEnable"
                class="confirm-btn" @click="confirm">
                {{ $t("feedbackDialog.submit") }}
            </FyButton>
        </div>
    </div>
</template>

<script>

import { CommonUtility } from '@/script/utility';
import FyButton from '../button/FyButton.vue';
import { i18n } from '@/i18n/index.js';

export default {
    name: 'FeedbackDialog',
    components: { FyButton },
    data () {
        return {
            faceSwapOptions: [
                { selected: false, msg: i18n.global.t('feedbackDialog.option1') },
                { selected: false, msg: i18n.global.t('feedbackDialog.option2') },
                { selected: false, msg: i18n.global.t('feedbackDialog.option3') },
                { selected: false, msg: i18n.global.t('feedbackDialog.option4') },
                { selected: false, msg: i18n.global.t('feedbackDialog.option5') },
            ],
            txtToImgOptions: [
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption1') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption2') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption3') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption4') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption5') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption6') },
                { selected: false, msg: i18n.global.t('feedbackDialog.txtOption7') },
            ],
            enhanceHistoryOptions: [
              { selected: false, msg: i18n.global.t('feedbackDialog.histOption1') },
              { selected: false, msg: i18n.global.t('feedbackDialog.histOption2') },
              { selected: false, msg: i18n.global.t('feedbackDialog.histOption3') },
              { selected: false, msg: i18n.global.t('feedbackDialog.histOption4') },
            ],
            visible: false,
            feedbackType: '',
            options: [],
            message: '',
            resolve: null,
            reject: null,
        };
    },
    computed: {
        confirmBtnEnable () {
            return this.message || this.options.some(item => item.selected);
        },

    },
    methods: {
        showDialog (feedbackType = '') {
            this.visible = true;
            this.feedbackType = feedbackType;
            switch (feedbackType) {
              case 'faceSwap':
                this.options = this.faceSwapOptions;
                break;
              case 'txtToImg':
                this.options = this.txtToImgOptions;
                break;
              case 'enhance':
                this.options = this.enhanceHistoryOptions;
                break;
              default:
                this.options = this.txtToImgOptions;
                break;
            }


            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        space (large, narrow) {
            if (window.innerWidth > 560) {
                return `height:${large}px`;
            }
                return `height:${narrow}px`;
        },
        itemOptionClick (index) {
          console.log('oooooooo');
            this.options[index].selected = !this.options[index].selected;
            // this.options.push({})
            // this.options.splice(this.options.length -1, 1)
        },
        assetImage (name) {
            return CommonUtility.assetImage(name);
        },
        confirm () {
            if (!this.confirmBtnEnable) {
                return;
            }
            let selectedArray = this.options.filter(item => item.selected).map(item => item.msg);
            this.resolve({ confirm: true, selectedArray, moreMsg: this.message });
            this.closeDialog();
        },
        cancelDialog () {
            this.resolve({ confirm: false });
            this.closeDialog();
        },
        closeDialog () {
            this.message = '';
            for (const option of this.options) {
                option.selected = false;
            }
            this.visible = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import './common.css';
.item_box{
  background: #F4F5F5;
  border-radius: 4px;
  width: max-content;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  text-align: center;
  font-style: normal;
  border: 1px solid #F4F5F5;
  cursor: pointer;

}

.item_box_hove:hover {
  color: #FF7839;
  border: 1px solid #FF7839;
}

.item_box_check{
  background: #FF7839;
  border: 1px solid #FF7839;
  color: #FFFFFF;
}
.title1 {
    width: 380px;
    height: 48px;

    font-weight: 600;
    font-size: 20px;
    color: black;
    text-align: center;
    align-self: center;
}

.title2 {
    width: auto;
    height: 24px;

    font-weight: 500;
    font-size: 16px;
    color: black;
    line-height: 24px;
}

.confirm-btn {
    width: 330px;
    height: 45px;
    align-self: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.itemBtn {
    height: 30px;
  border: 1px solid transparent;
}

.itemBtn:hover {
  color: #FF7839;
  border: 1px solid #FF7839;
}

.item-text {
    height: 14px;

    font-weight: 400;
    font-size: 12px;
    color: black;
    line-height: 14px;
    margin: 8px 10px;
}
.item-text[status="highlight"] {
    color: white;
}

.item-text[status="highlightBo"] {
  color: #FF7839;
}

.textarea {
    width: 424px;
    height: 70px;

    font-weight: 400;
    font-size: 12px;
    color: black;
    line-height: 14px;
    text-align: left;
    resize: none;
    background: transparent;
    box-sizing: border-box;
    border: 0;
}

.textarea:focus {
    outline: none;
}

.dialog {
    width: 520px;
    height: auto;
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    align-items: start;
    flex-direction: column;
    position: relative;

}

.wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
}

.textarea-container {
    width: 444px;
    height: 90px;
    background: #F4F5F5;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
}

.cancle-btn {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }

}
.def_cla_img {
  display: block;
}
.hove_cla_img {
  display: none;
}
.cancle-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
  .def_cla_img {
    display: none;
  }
  .hove_cla_img {
    display: block;
  }
}

@media (max-width: 560px) {
    .dialog {
        width: 90%;
        height: auto;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px 5% 20px 5%;
        display: flex;
        align-items: start;
        flex-direction: column;
    }

    .title1 {
        width: 258px;
        height: 42px;

        font-weight: 600;
        font-size: 16px;
        color: black;
        text-align: center;
        align-self: center;
    }


    .itemBtn {
        height: 30px;
    }

    .textarea-container {
        width: 100%;
        height: 90px;
        background: #F4F5F5;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
    }

    .textarea {
        width: 100%;
        height: 70px;

        font-weight: 400;
        font-size: 12px;
        color: black;
        line-height: 14px;
        text-align: left;
        resize: none;
        background: transparent;
        box-sizing: border-box;
        border: 0;
    }

    textarea:focus {
        font-size: 16px;
        line-height: 18px;
        color: black;
    }

    .confirm-btn {
        width: 200px;
        height: 36px;
        align-self: center;

        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }

}
</style>

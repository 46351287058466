import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/refund-policy",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refundPolicy/${locale}.js`)).default
			return import('@/views/refundPolicy/RefundPolicyPage.vue')
		}
	},
	{
		path: "/remboursement-politique",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refundPolicy/${locale}.js`)).default
			return import('@/views/refundPolicy/RefundPolicyPage.vue')
		}
	},
	{
		path: "/reembolso-pol%C3%ADtica",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refundPolicy/${locale}.js`)).default
			return import('@/views/refundPolicy/RefundPolicyPage.vue')
		}
	},
	{
		path: "/%E8%BF%94%E9%87%91-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/refundPolicy/${locale}.js`)).default
			return import('@/views/refundPolicy/RefundPolicyPage.vue')
		}
	},
]
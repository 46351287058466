import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/contact-support",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/contactSupport/${locale}.js`)).default
			return import('@/views/contactSupport/ContactSupportPage.vue')
		}
	},
	{
		path: "/contact-soutien",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/contactSupport/${locale}.js`)).default
			return import('@/views/contactSupport/ContactSupportPage.vue')
		}
	},
	{
		path: "/contacto-apoyo",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/contactSupport/${locale}.js`)).default
			return import('@/views/contactSupport/ContactSupportPage.vue')
		}
	},
	{
		path: "/contato-apoiar",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/contactSupport/${locale}.js`)).default
			return import('@/views/contactSupport/ContactSupportPage.vue')
		}
	},
	{
		path: "/%E6%8E%A5%E8%A7%A6-%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/contactSupport/${locale}.js`)).default
			return import('@/views/contactSupport/ContactSupportPage.vue')
		}
	},
]
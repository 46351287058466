import { AnalyticHub } from './analytic.js';
import { ENV } from './proj_env.js';
import { User } from '@/stores/user.js';
import { CommonUtility } from './utility.js';
import { md5 } from 'js-md5';

export class API {
  static REQUEST_STATUS_NONE = 0;
  static REQUEST_STATUS_LOADING = 1;
  static REQUEST_STATUS_SCUCESS = 2;
  static REQUEST_STATUS_FAILED = -1;

  static async post (url, inputParams, timeout = 30000) {
    if (!url) throw this.REQUEST_STATUS_FAILED;
    return await new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.ontimeout = () => {
        AnalyticHub.getInstance.log('server_delay');
        reject(this.REQUEST_STATUS_FAILED);
      };
      xhr.onerror = () => {
        reject(this.REQUEST_STATUS_FAILED);
      };
      xhr.timeout = timeout;

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              let jsonResponse = JSON.parse(xhr.responseText);
              resolve(jsonResponse);
            } catch (e) {
              reject(this.REQUEST_STATUS_FAILED);
            }
          } else {
            reject(this.REQUEST_STATUS_FAILED);
          }
        }
      };

      const params = commonParams();
      if (inputParams) {
        Object.keys(inputParams).forEach((key) => {
          params[key] = inputParams[key];
        });
      }
      let keys = [];
      Object.keys(params).forEach((key) => {
        keys.push(key);
      });

      keys = keys.sort();
      const signPairs = [];
      const bodyPairs = [];
      for (const key of keys) {
        bodyPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
        signPairs.push(`${key}=${params[key]}`);
      }
      let sign = signPairs.join('&');
      sign = md5(`${sign}FtA50esxi`);
      bodyPairs.push(`sign=${sign}`);
      const body = bodyPairs.join('&');
      xhr.send(body);
    });
  }

  static async upload (url, inputParams, progressListener, ...files) {
    if (!url) throw this.REQUEST_STATUS_FAILED;
    try {
      const formData = new FormData();
      
      let params = commonParams();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      if (inputParams) {
        Object.keys(inputParams).forEach((key) => {
          formData.append(key, inputParams[key]);
        });
      }
      if (files) {
        for (let file of files) {
          formData.append(file.name, file.file);
          formData.append('id', file.id);
        }
      }
      
      let resp = await new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        if (progressListener) {
          xhr.upload.addEventListener(
            'progress',
            (d) => {
              if (d.lengthComputable) {
                let uploadPercent = d.loaded / d.total;
                progressListener(uploadPercent);
              }
            },
            false
          );
        }
        xhr.onload = () => {
          try {
            let resp = JSON.parse(xhr.response);
            resolve(resp);
          } catch (_) {
            reject(this.REQUEST_STATUS_FAILED);
          }
        };
        xhr.onerror = () => {
          reject(this.REQUEST_STATUS_FAILED);
        };
        xhr.open('POST', url, true);
        xhr.send(formData);
      });
      return resp;
    } catch (e) {
      throw this.REQUEST_STATUS_FAILED;
    }
  }

  /// 站长邀请浏览量记录
  static async affiliateVisit (affiliateKey) {
    return await API.post(`${ENV.currentConfig.apiHost}/affiliate/visit`, { affiliateKey });
  }

  /// 提交换脸上传
  static async swapUploadDetectFace (
    { taskId, promptTaskId, editTaskId, progress },
    file
  ) {
    let params = {};
    if (taskId) {
      params.taskId = taskId;
    }
    if (promptTaskId) {
      params.promptTaskId = promptTaskId;
    }
    if (editTaskId) {
      params.editTaskId = editTaskId;
    }
    return await API.upload(
      `${ENV.currentConfig.apiHost}/swap/detect_face`,
      params,
      progress,
      {
        name: 'file',
        file,
      }
    );
  }

  /// 获取换脸文件上传任务详情
  static async swapUploadTaskFetch (taskIds) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/get_face_analyser_task_detail`,
      { taskIds: taskIds.join(',') }
    );
  }

  /// 获取换脸上传历史
  static async swapUploadHistory () {
    return await API.post(`${ENV.currentConfig.apiHost}/swap/get_face_analyser_history`);
  }

  /// 删除换脸上传历史文件
  static async swapDeleteUploadHistoryFile (taskId) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/delete_face_analyser_history`,
      { taskIds: taskId }
    );
  }

  /// 获取换脸分析执行中任务列表
  static async swappingTasks () {
    return await API.post(`${ENV.currentConfig.apiHost}/swap/get_face_swapper_tasks`);
  }

  /// 提交换脸任务
  static async swapSubmit ({ fileKey, faceMappings, taskId, localTaskId }) {
    if (!fileKey) throw -1;
    if (!faceMappings) throw -1;
    let params = {
      fileKey,
      faceMappings,
    };
    if (localTaskId) {
      params.localTaskId = localTaskId;
    }
    if (taskId) {
      params.taskId = taskId;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/swap_face`,
      params,
      300000
    );
  }

  /// 获取换脸任务详情
  static async swapTaskFetch (taskIds) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/get_face_swapper_task_detail`,
      { taskIds: taskIds.join(',') }
    );
  }

  /// 上传人脸头像
  static async faceUpload (file) {
    return await API.upload(`${ENV.currentConfig.apiHost}/face/detect_face`, {
      name: 'file',
      file,
    });
  }

  /// 删除人脸头像
  static async faceDelete (faceId) {
    return await API.post(`${ENV.currentConfig.apiHost}/face/delete_face`, { faceId });
  }

  /// 人脸头像历史
  static async facesList () {
    return await API.post(`${ENV.currentConfig.apiHost}/face/get_face`, null);
  }

  /// 获取用户数据
  static async loadUserInfo () {
    return await API.post(`${ENV.currentConfig.apiHost}/user/getinfo`);
  }

  /// 获取config配置数据
  static async loadConfigInfo () {
    return await API.post(`${ENV.currentConfig.apiHost}/setting/infos`);
  }

  /** 换脸反馈 */
  static async swapFeedBack (args) {
    return await API.upload(
      `${ENV.currentConfig.apiHost}/contactus/swap_feedback`,
      args
    );
  }

  /** 文生图反馈 */
  static async txtToImgFeedBack (args) {
    return await API.upload(
      `${ENV.currentConfig.apiHost}/contactus/image_feedback`,
      args
    );
  }

  /// google注册
  static async googleUserRegister ({ email, googleIdToken, googleAccessToken }) {
    let params = { email };
    if (googleIdToken) {
      params.googleIdToken = googleIdToken;
    } else if (googleAccessToken) {
      params.googleAccessToken = googleAccessToken;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/register_google`,
      params,
    );
  }

  /// 登录
  static async userLogin ({
    email,
    password,
    code,
    googleIdToken,
    googleAccessToken,
  }) {
    let params = { email };
    if (password) {
      params.password = password;
    }
    if (code) {
      params.code = code;
    }
    if (googleIdToken) {
      params.googleIdToken = googleIdToken;
    }
    if (googleAccessToken) {
      params.googleAccessToken = googleAccessToken;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/loginin`,
      params,
    );
  }

  /// 邮箱注册不需要验证码
  static async userRegister ({ email, password, referralKey, affiliateKey }) {
    let params = {
      email,
      password,
    };
    if (referralKey) {
      params.referralKey = referralKey;
    }
    if (affiliateKey) {
      params.affiliateKey = affiliateKey;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/register_email_new`,
      params,
    );
  }

  /// 注册发送验证码
  static async userSendRegisterCode ({ email }) {
    let params = { email };
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/sendregistercode`,
      params
    );
  }

  /// 邮箱验证码注册
  static async userRegisterVerify ({
    email,
    password,
    verifyCode,
    referralKey,
  }) {
    let params = {
      email,
      password,
      code: verifyCode,
    };
    if (referralKey) {
      params.referralKey = referralKey;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/register_email`,
      params,
      3000,
      true,
    );
  }

  /// 重置密码
  static async resetPassword ({ email, code, newPassword }) {
    let params = {
      email,
      code,
      newPassword,
    };
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/resetPassword`,
      params
    );
  }

  /// 重置密码验证码获取
  static async sendForgotEmailCode ({ email }) {
    let params = { email };
    return await API.post(
      `${ENV.currentConfig.apiHost}/user/sendforgotcode`,
      params
    );
  }

  /// 用户设备注册
  static async userDeviceOpen () {
    return await API.post(`${ENV.currentConfig.apiHost}/user/open`);
  }

  /// 获取所有hint tag
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68364
  static async getHintTags () {
    return await API.post(`${ENV.currentConfig.apiHost}/ai/hinttags`);
  }
  /// 获取推荐prompt文本列表
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68365
  static async getSuggestionList () {
    return await API.post(`${ENV.currentConfig.apiHost}/ai/suggestionList`);
  }
  /// 获取随机prompt文本
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68367
  static async getSuggestionRandom () {
    return await API.post(`${ENV.currentConfig.apiHost}/ai/suggestionRandom`);
  }
  /// 获取所有风格列表
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68368
  static async getStyleList () {
    return await API.post(`${ENV.currentConfig.apiHost}/ai/styleList`);
  }
  /// 提交文生图任务
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68386
  static async txtToImgSubmit ({
    prompt,
    styleKey,
    ratio,
    faceId,
    batch,
    localTaskId,
    autoPublish,
  }) {
    const params = {
      prompt,
      styleKey,
      ratio,
      batch,
      autoPublish,
    };
    if (faceId) {
      params.faceId = faceId;
    }
    if (localTaskId) {
      params.localTaskId = localTaskId;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/ai/submitPromptInfoNoFile`,
      params
    );
  }

  /// 获取文生图任务详情
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259789
  static async txtToImgTasksFetch (taskIds) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/ai/get_prompt_task_detail`,
      { localTaskIds: taskIds.join(',') }
    );
  }
  // 文生图图片发布
  static async txtToImgpublishFetch (taskIds) {
    return await API.post(`${ENV.currentConfig.apiHost}/publish/to_examine`, { taskIds });
  }

  /// 获取已完成任务的历史记录-文生图
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68387
  static async txtToImgTasksHistoryList (oldestTaskId) {
    if (oldestTaskId) {
      return await API.post(`${ENV.currentConfig.apiHost}/ai/get_history`, { oldestTaskId });
    } 
      return await API.post(`${ENV.currentConfig.apiHost}/ai/get_history`);
  }

  /// 获取还在生成中的任务-文生图
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68389
  static async txtToImgTasksList () {
    return await API.post(`${ENV.currentConfig.apiHost}/ai/get_tasks`);
  }

  // 删除文生图历史文件
  // http://123.57.94.25:8090/pages/viewpage.action?pageId=68375
  static async deleteTxtToImgHistoryFile (taskId) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/ai/delete_history`,
      { taskIds: taskId }
    );
  }

  /// 获取换脸任务详情
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259783
  static async swapFaceTasksFetch (taskIds) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/get_face_swapper_task_detail`,
      { taskIds: taskIds.join(',') }
    );
  }

  /// 获取换脸分析任务详情
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259785
  static async swapAnalyzeTasksFetch (taskIds) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/get_face_analyser_task_detail`,
      { taskIds: taskIds.join(',') }
    );
  }

  /// 获取已完成任务的历史记录-换脸
  /// hhttp://123.57.94.25:8090/pages/viewpage.action?pageId=68383
  static async swapFaceTasksHistoryList (oldestTaskId) {
    if (oldestTaskId) {
      return await API.post(
        `${ENV.currentConfig.apiHost}/swap/get_face_swapper_history`,
        { oldestTaskId }
      );
    } 
      return await API.post(`${ENV.currentConfig.apiHost}/swap/get_face_swapper_history`);
  }

  /// 获取还在生成中的任务-换脸
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68384
  static async swapFaceTasksList () {
    return await API.post(`${ENV.currentConfig.apiHost}/swap/get_face_swapper_tasks`);
  }

  // 删除换脸历史文件
  // http://123.57.94.25:8090/pages/viewpage.action?pageId=68375
  static async deleteSwapFaceHistoryFile (taskId) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/swap/delete_face_swapper_history`,
      { taskIds: taskId }
    );
  }
  /// 获取商品信息
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259844
  static async loadPurchaseProducts () {
    return await API.post(`${ENV.currentConfig.apiHost}/subscription/get_goods_info`);
  }

  /// 获取最新支付订单是否成功
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259841
  static async checkLastPurchaseOrderSuccess (timestamp) {
    return await API.post(`${ENV.currentConfig.apiHost}/product/order_latest`, { createTimestamp: timestamp });
  }

  // 用户反馈
  static async contactusUpload (parame) {
    return await API.upload(
      `${ENV.currentConfig.apiHost}/contactus/upload`,
      parame,
      null,
      {
        name: 'file',
        file: parame.file,
      }
    );
  }
  /// 获取积分变化历史
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68556
  static async loadCreditsHistory (oldestCreditKey, token) {
    const params = {};
    if (oldestCreditKey) {
      params.oldestCreditKey = oldestCreditKey;
    }
    if (token) {
      params.token = token;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/product/credit_history`,
      params
    );
  }

  /// 获取订单历史
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=68552
  static async loadOrdersHistory (oldestTransactionId, token) {
    const params = {};
    if (oldestTransactionId) {
      params.oldestTransactionId = oldestTransactionId;
    }
    if (token) {
      params.token = token;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/product/order_history`,
      params
    );
  }

  // 查询收益记录-汇总
  static async affiliateGetActivity (parame) {
    /**
     * 
      let s = {
        oldestAffiliateKey: '当前记录中，最老的收益记录对应的key, 翻页的时候用到',
        startCtime: '开始时间， 秒时间戳',
        endCtime: '结束时间， 秒时间戳',
      };
     * **/ 
    
    return await API.post(
      `${ENV.currentConfig.apiHost}/affiliate/get_activity`,
      parame
    );
  }
  // 查询收益记录-明细
  static async affiliateGetActivityDetail (parame) {
    /**
     * 
      let s = {
        oldestAffiliateKey: '当前记录中，最老的收益记录对应的key, 翻页的时候用到',
        startCtime: '开始时间， 秒时间戳',
        endCtime: '结束时间， 秒时间戳',
        affiliateType: '收益对应的类别， 0：注册， 1：充值',
      };
     * **/ 
    return await API.post(
      `${ENV.currentConfig.apiHost}/affiliate/get_activity_detail`,
      parame
    );
  }
  // 查询收益记录-明细
  static async getPayoutActivityListData (parame = {}) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/affiliate/get_payout`,
      parame
    );
  }

  /// 绑定提现账号
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259761
  static async bindPayoutAccount (payeeAccount, password) {
    const params = {};
    // if (payeeAccount || password) {
    //   params['payeeAccount'] = payeeAccount
    //   params['password'] = password
    // }
    if (payeeAccount) {
      params.payeeAccount = payeeAccount;
    }
    if (password) {
      params.password = md5(`${password}asdzxucoihk1`);
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/affiliate/bind_payout_account`,
      params
    );
  }

  /// 进行提现操作
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259745
  static async payout (revenue, payeeAccount, description) {
    const params = {};
    // revenue 提现金额必传
    params.revenue = revenue;
    if (payeeAccount || description) {
      params.payeeAccount = payeeAccount;
      params.description = description;
    }
    return await API.post(
      `${ENV.currentConfig.apiHost}/affiliate/payout`,
      params
    );
  }

  /// 设备上没有udid的情况下，需要记一次open user
  /// http://123.57.94.25:8090/pages/viewpage.action?pageId=33259826
  static async openUser () {
    return await API.post(`${ENV.currentConfig.apiHost}/user/open`, {});
  }

  // 获取还在生成中的任务-enhance
  static async getEnhacneTasksList () {
    return await API.post(`${ENV.currentConfig.apiHost}/enhance/get_tasks`);
  }

  // 获取历史记录
  static async getEnhanceHistoryList (oldestTaskId) {
    if (oldestTaskId) {
      return await API.post(
        `${ENV.currentConfig.apiHost}/enhance/get_enhance_task_history`,
        { oldestTaskId }
      );
    } 
      return await API.post(`${ENV.currentConfig.apiHost}/enhance/get_enhance_task_history`);
  }

  // 删除enhancee历史文件
  static async deleteEnhacneHistoryFile (taskId) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/enhance/del_enhance_task_history`,
      { taskId }
    );
  }

  /// enHance文件上传
  static async enhanceFileUploadOld ({ file_url, type, progress }, file) {
    let params = {};
    if (file_url) {
      params.file_url = file_url;
    }
    params.type = type;
    // console.log(params)
    return await API.upload(
      file_url ? `${ENV.currentConfig.apiHost}/enhance/file_upload` : `${ENV.currentConfig.apiHost}/enhance/file_confirm`,
      params,
      progress,
      {
        name: 'file',
        file,
      }
    );
  }

  // /enhance/create_by_file

  static async enhanceFileUpload ({ file_url, type, progress }, file) {
    let params = {};
    if (file_url) {
      params.file_url = file_url;
    }
    params.type = type;
    // console.log(params)
    return await API.upload(
      file_url ? `${ENV.currentConfig.apiHost}/enhance/create_by_url` : `${ENV.currentConfig.apiHost}/enhance/create_by_file`,
      params,
      progress,
      {
        id: +new Date(),
        name: 'file',
        file,
      }
    );
  }

  //
  static async getEnhanceFileAnalyserDetail (taskId) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/enhance/get_enhance_file_analyser_detail`,
      { taskId }
    );
  }

  /** enhance反馈 */
  static async enHanceFeedBack (args) {
    return await API.upload(
      `${ENV.currentConfig.apiHost}/contactus/swap_feedback`,
      args
    );
  }
  // enhance文件提交处理
  static async enhanceSubmit (data) {
    return await API.post(`${ENV.currentConfig.apiHost}/enhance/submit`, data);
  }

  // enhance任务进度查询
  static async enhanceGetTaskDetail (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/enhance/get_enhance_task_history_detail`,
      data
    );
  }

  // /enhance/get_preview_task_detail
  static async enHancePreviewTaskDetail (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/enhance/get_preview_task_detail`,
      data
    );
  }


  static async enhanceGetTaskDetailCreate (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/enhance/get_task_detail`,
      data
    );
  }


  // 创建Kodepay会话
  static async createKodepaySession (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/subscription/create_kodepay_session`,
      data,
    );
  }

  // 创建Useepay支付会话
  static async createUseepaySession (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/subscription/create_useepay_session`,
      data,
    );
  }
  

  // 邮件通知 获取用户通知设置参数接口
  static async getUserNoticeInfo (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/notice_user/get_user_notice_info`,
      data,
    );
  }

  // 邮件通知 修改用户通知参数接口
  static async updateUserNoticeInfo (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/notice_user/update_user_notice_info`,
      data,
    );
  }

  // 创建oceanpayment支付会话
  static async createOceanpaymentSession (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/subscription/create_oceanpayment_session`,
      data,
    );
  }

  // 获取jpg
  static async convertImage (data) {
    return await API.post(
      `${ENV.currentConfig.apiHost}/convert/convert_image`,
      data,
    );
  }
}

function commonParams () {
  let lang = ENV.currentConfig.lang;
  let country = lang.split('-')[0];
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timezoneOfset = Math.abs(new Date().getTimezoneOffset() * 60);
  let timezoneStr = `${timezone} ${timezoneOfset}`;
  let params = {
    packageName: ENV.currentConfig.packageName,
    appVersion: ENV.currentConfig.version,
    language: lang,
    countryCode: country,
    region: country,
    deviceType: navigator.userAgent,
    timestamp: parseInt(new Date().valueOf() / 1000),
    udid: CommonUtility.getUuid(),
    timezone: timezoneStr,
    platform: 'web',
  };
  params.token = User.getInstance.token.value;
  return params;
}

import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/account-information",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/UserCenterPage.vue')
		}
	},
	{
		path: "/compte-information",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/UserCenterPage.vue')
		}
	},
	{
		path: "/cuenta-informaci%C3%B3n",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/UserCenterPage.vue')
		}
	},
	{
		path: "/conta-Informa%C3%A7%C3%A3o",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/UserCenterPage.vue')
		}
	},
	{
		path: "/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88-%E6%83%85%E5%A0%B1",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/UserCenterPage.vue')
		}
	},
	{
		path: "/account-information/notification",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/EmailNoticeSetting.vue')
		}
	},
	{
		path: "/informations-sur-le-compte/notification",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/EmailNoticeSetting.vue')
		}
	},
	{
		path: "/informaci%C3%B3n-de-cuenta/notificaci%C3%B3n",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/EmailNoticeSetting.vue')
		}
	},
	{
		path: "/informa%C3%A7%C3%B5es-da-conta/notifica%C3%A7%C3%A3o",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/EmailNoticeSetting.vue')
		}
	},
	{
		path: "/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E6%83%85%E5%A0%B1/%E9%80%9A%E7%9F%A5",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/EmailNoticeSetting.vue')
		}
	},
	{
		path: "/payment-credit-history",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/PaymentOrCreditHistoryPage.vue')
		}
	},
	{
		path: "/paiement-cr%C3%A9dit-histoire",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/PaymentOrCreditHistoryPage.vue')
		}
	},
	{
		path: "/pago-cr%C3%A9dito-historia",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/PaymentOrCreditHistoryPage.vue')
		}
	},
	{
		path: "/pagamento-cr%C3%A9dito-hist%C3%B3ria",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/PaymentOrCreditHistoryPage.vue')
		}
	},
	{
		path: "/%E6%94%AF%E6%89%95%E3%81%84-%E3%82%AF%E3%83%AC%E3%82%B8%E3%83%83%E3%83%88-%E6%AD%B4%E5%8F%B2",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/userCenter/${locale}.js`)).default
			return import('@/views/userCenter/PaymentOrCreditHistoryPage.vue')
		}
	},
]
import { CommonUtility } from '@/script/utility';
import setup from '@/script/setup';
import i18n from '@/i18n/index';
import app from '@/app';
import router from './router';
import { ENV } from './script/proj_env';
import { switchLocale } from './router/tool';

// 如果界面语言为英语，且浏览器语言不为英语，需要自动做一次重定向
const uiI18nCode = ENV.currentConfig.lang;
if (uiI18nCode === 'en' && localStorage.getItem('autoSwitchedI18n') !== '1') {
    localStorage.setItem('autoSwitchedI18n', '1');
    let lang = `${navigator.language || navigator.userLanguage}`;
    if (lang.startsWith('fr')) {
        switchLocale('fr');
    } else if (lang.startsWith('es')) {
        switchLocale('es');
    } else if (lang.startsWith('pt')) {
        switchLocale('pt');
    } else if (lang.startsWith('ja')) {
        switchLocale('ja');
    }
}

CommonUtility.checkWebpSupport().then(() => {
    app.use(setup);
    app.use(i18n);
    app.use(router);
    app.mount('#app');
});
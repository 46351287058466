import { i18n } from '@/i18n/index.js'
import { ENV } from '@/script/proj_env';
export default [
	{
		path: "/free-ai-image-generator",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/txtToImg/${locale}.js`)).default
			return import('@/views/txtToImg/TxtToImgPage.vue')
		}
	},
	{
		path: "/gratuit-ai-image-g%C3%A9n%C3%A9rateur",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/txtToImg/${locale}.js`)).default
			return import('@/views/txtToImg/TxtToImgPage.vue')
		}
	},
	{
		path: "/gratis-ai-imagen-generador",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/txtToImg/${locale}.js`)).default
			return import('@/views/txtToImg/TxtToImgPage.vue')
		}
	},
	{
		path: "/livre-ai-imagem-gerador",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/txtToImg/${locale}.js`)).default
			return import('@/views/txtToImg/TxtToImgPage.vue')
		}
	},
	{
		path: "/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF",
		meta: {
			flag: "#",
		},
		component: async () => {
			const locale = ENV.currentConfig.lang
			i18n.global.messages[locale] = (await import(`@/i18n/txtToImg/${locale}.js`)).default
			return import('@/views/txtToImg/TxtToImgPage.vue')
		}
	},
]